<template>
  <!-- 输注中心详情 -->
  <div class="center_detail_container">
    <div class="notice">
      <p class="tip">取消后将不会为您保留预约名额</p>
    </div>

    <div class="center_wrapper">
      <span class="name" @click="handleToCenter">
        {{ queryObj.infusionCenterName }}
      </span>
      <p class="info">患者姓名：{{ desensitizeName(queryObj.patientName) }}</p>
      <p class="info">手机号：{{ desensitizePhone(queryObj.patientPhone) }}</p>
      <p class="info">
        预约输注时间：<span class="light"
          >{{ `${queryObj.infusionDate.split(' ')[0]}` }}
          {{ `${queryObj.infusionWeek}` }}
          {{ `${queryObj.infusionTime}` }}</span
        >
      </p>
      <p class="info">申请时间：{{ queryObj.createdTime }}</p>
    </div>

    <div class="reason_wrapper">
      <!-- <span>*</span> -->
      <p class="title">请勾选取消原因以帮助我们改善服务</p>
      <van-radio-group v-model="cancelType" checked-color="#F36218">
        <van-radio name="1">计划有变，不想去了</van-radio>
        <van-radio name="2">预约时间调整，想重新预约</van-radio>
        <van-radio name="3">输注中心通知无法接待</van-radio>
        <van-radio name="4">其他</van-radio>
      </van-radio-group>

      <textarea
        v-if="+cancelType === 4"
        class="reason_detail"
        placeholder="请填写详细原因"
        v-model="cancelComment"
        maxlength="50"
      ></textarea>
    </div>

    <!-- 底部操作按钮 -->
    <bottom-btns
      :btns="[
        {
          text: '确定取消',
          type: 'primary',
          eventName: 'cancel',
        },
      ]"
      @cancel="handleClickPreengage"
    />
  </div>
</template>

<script>
import InfusionCenterInfo from '@/components/infusion-center-info/index.vue';
import CardInfo from '@/components/card-info/index.vue';
import BottomBtns from '@/components/bottom-btns/index.vue';
import api from '@/api'
import { Toast } from 'vant'
import { desensitizePhone, desensitizeName } from '@/utils';

export default {
  // 取消预约页面
  name: 'ApplyCancel',
  components: {
    InfusionCenterInfo,
    CardInfo,
    BottomBtns
  },
  data () {
    return {
      desensitizePhone,
      desensitizeName,
      cancelType: '1',
      cancelComment: '',
      queryObj: {}
    }
  },

  created () {
    this.queryObj = this.getQueryStringArgs()
  },
  methods: {
    handleToCenter () {
      this.$router.push({
        path: '/center-detail',
        query: {
          id: this.queryObj.infusionCenterCode
        }
      })
    },
    // 获取url query参数方法
    getQueryStringArgs () {
      const qs = location.search.length > 0 ? location.search.substring(1) : ''
      const args = {};
      const arr = qs.split('&').map((kv) => kv.split('='));
      arr.forEach((item) => {
        args[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
      });
      return args;
    },

    // 取消预约
    async handleClickPreengage () {
      const res = await api.cancelApply({
        cancelComment: this.cancelComment && this.cancelComment.trim(),
        cancelType: this.cancelType,
        id: this.queryObj.id
      })

      console.log(res.code, res.code === '0')
      if (res.code === '0') {
        Toast.success('取消成功')
        this.$router.go(-1)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.center_detail_container {
  padding-bottom: 120px;

  .notice {
    width: 100%;
    height: 36px;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #fff7f4;
    box-shadow: inset 0px 0px 0px 0px rgba(243, 98, 24, 0.1);

    .tip {
      height: 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ff6b26;
      line-height: 17px;
      text-align: left;
      font-style: normal;
    }
  }

  .center_wrapper {
    margin-bottom: 8px;
    padding: 20px 16px;
    background: #fff;
    .name {
      margin-bottom: 8px;
      // height: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #3a3a3a;
      // line-height: 20px;
      font-style: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .info {
      margin-top: 4px;
      // width: 112px;
      height: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      // text-align: left;
      font-style: normal;
      .light {
        color: #f36218;
      }
    }
  }

  .reason_wrapper {
    padding: 11px 16px;
    background: #fff;
    .title {
      margin-bottom: 14px;
      height: 22px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #3a3a3a;
      line-height: 22px;
      font-style: normal;
    }

    /deep/ .van-radio {
      margin-bottom: 12px;
    }
    /deep/ .van-radio__label {
      font-size: 16px;
      color: #565656;
    }

    .reason_detail {
      border: none;
      width: 340px;
      height: 130px;
      background: #f5f7fa;
      border-radius: 6px;
      padding: 9px 12px;
      box-sizing: border-box;
      font-size: 16px;
    }
  }
}
</style>
